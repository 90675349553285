@font-face {
  font-family: 'Barlow Condensed';
  src: url('./fonts/BarlowCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('./fonts/BarlowCondensed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('./fonts/BarlowCondensed-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('./fonts/BarlowCondensed-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Montserrat Medium */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Montserrat Bold */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pac-container {
  z-index: 2000 !important;
}

select option {
  background-color: #4D3650 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.rc-md-editor .editor-container .sec-md .input {
  min-height: 150px;
}

.rc-md-editor .rc-md-navigation {
  border-bottom: 1px solid grey !important;
}

.rc-md-editor .editor-container .sec-md .input, .rc-md-editor .rc-md-navigation, .rc-md-editor .editor-container .sec-html .html-wrap {
  background-color: #2B2C2C !important;
  color: white !important;
}
.rc-md-editor .editor-container .sec-md {
  background-color: #2B2B2B !important;
}

.rc-md-editor .editor-container .sec-md .input {
  border-radius: 0 0 0.375rem 0.375rem !important;
  resize: vertical !important;
  overflow-y: auto !important;
}

.custom-html-style code, .custom-html-style pre {
  border-radius: 0.375rem !important;
  color: white !important;
  background-color: #2B2B2B !important;
}

.rc-md-editor .rc-md-navigation {
  border-radius: 0.375rem 0.375rem 0 0 !important;
}

.rc-md-editor {
  padding-bottom: 0 !important;
  background-color: #2B2B2B !important;
}

.rc-md-editor .custom-html-style {
  background-color: #2B2C2C !important;
  color: white !important;
}

.custom-editor .rc-md-editor {
  color: white !important;
}

.blur {
  filter: blur(4px);
}

/* Animation pour retirer le flou */
.no-blur-animation {
  animation: blurOut 0.5s forwards;
}

@keyframes blurOut {
  0% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}

.react-datepicker {
  min-width: 328px !important;
}

li {
  color: white !important;
}
